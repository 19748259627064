const DownloadLink = () => {
  // Function to check if the user is on iOS
  const isIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Function to check if the user is on Android
  const isAndroid = () => /Android/.test(navigator.userAgent);

  // Function to redirect based on platform
  const redirectDeepLink = () => {
    // window.location.href = `https://bit.ly/4bEgp6c`;
    if (isIOS()) {
      window.location.href = `https://apps.apple.com/us/app/unleash-pets-lifestyle-app/id6451282476`; // Replace with your App Store URL
    } else if (isAndroid()) {
      window.location.href = `https://play.google.com/store/apps/details?id=com.hs.unleash`; // Replace with your App Store URL
    } else {
      console.log("first");
      return (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src="/assets/images/qr-code-bit-ly.png" alt="" />
        </div>
      );
      // If not on iOS or Android, open the deep link directly
      // window.close();
    }
  };

  // useEffect(() => {
  //   redirectDeepLink()
  // }, [])

  return redirectDeepLink();
};

export default DownloadLink;
