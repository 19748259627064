import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Spinner from "../components/atoms/Spinner";
// import ShareLinkRedirect from "../pages/share";
import DownloadLink from "../pages/download-link";

// eslint-disable-next-line react/display-name
const Loadable = Component => props =>
(
  <Suspense fallback={
    <Spinner isLoading={true} text=" " size="lg" />
  }>
    <Component {...props} />
  </Suspense>
)

const Homepage = Loadable(
  lazy(() => import("../pages/index"))
)

const JoinUsPage = Loadable(
  lazy(() => import("../pages/join"))
)

const InquiriesPage = Loadable(
  lazy(() => import("../pages/inquiries"))
)

const ReadsListPage = Loadable(
  lazy(() => import("../pages/reads/list"))
)

const ViewReadPage = Loadable(
  lazy(() => import("../pages/reads/view"))
)

const PrivacyPolicyPage = Loadable(
  lazy(() => import("../pages/privacy/policy"))
)

const TermsAndConditionPage = Loadable(
  lazy(() => import("../pages/terms-and-condition"))
)

const CommunityGuidelinesPage = Loadable(
  lazy(() => import("../pages/community/guidelines"))
)

const SafetyTipsPage = Loadable(
  lazy(() => import("../pages/community/safety-tips"))
)

const CookiePolicyPage = Loadable(
  lazy(() => import("../pages/privacy/cookie-policy"))
)

const DeleteAccountPage = Loadable(
  lazy(() => import("../pages/delete-account"))
)

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/join" element={<JoinUsPage />} />
        <Route exact path="/private/inquiries" element={<InquiriesPage />} />
        <Route exact path="/terms-and-condition" element={<TermsAndConditionPage />} />
        <Route exact path="/privacy/policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/community/guidelines" element={<CommunityGuidelinesPage />} />
        <Route exact path="/community/safety-tips" element={<SafetyTipsPage />} />
        <Route exact path="/privacy/cookie-policy" element={<CookiePolicyPage />} />
        {/* <Route exact path="/share/:type/:id" element={<ShareLinkRedirect />} /> */}
        <Route exact path="/download-link" element={<DownloadLink />} />
        <Route path="reads">
          <Route path="list">
            <Route path="" element={<ReadsListPage />} />
            <Route path=":id" element={<ViewReadPage />} />
          </Route>
        </Route>
        <Route exact path="/delete-account" element={<DeleteAccountPage />} />
      </Routes>
    </Router>
  )
}

export default AppRouter;

